/* eslint-disable react/display-name */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/button-has-type */
/* eslint-disable react/no-danger */
import React, { memo, useState, useEffect, useRef, useContext } from "react"
import styled from "styled-components"

import Inner from "../../zzz/layout/pageInner/Inner"

import BranchContext from "../../context/BranchContext"

import { slugify, includes, split } from "voca"
import { path, addSizeFormat, lookupShippingClass } from "../../lib/util"

import ProductCard from "../ProductSingle/ProductCard"
import LazyLoadWrapper from "../../utils/lazyLoadWrapper"
import EllipsisLoader from "../../zzz/organisms/ellipsisLoader"

const ProductList = memo(
  ({
    productList,
    showProductDeals = false,
    homePageBestDeals = false,
    productsPerLoad = 32,
    sizesFilter = [],
    shippingFilter = [],
    clearFilterIfEmpty = false,
    sort = false,
    disableProductOrderSort = false,
    discountPercentageLimit = false,
    loading = false,
    searchAnalyticsFunction,
    setParentProductListLength = () => {},
  }) => {
    if (loading) {
      return (
        <Inner>
          <Loading />
        </Inner>
      )
    }
    let expandedProductList = []
    const prevExpandedProductList = usePrevious(expandedProductList)
    const [currentIndex, setCurrentIndex] = useState(productsPerLoad)
    const [givenSizeFilter, setGivenSizeFilter] = useState([])

    const { selectedBranch } = useContext(BranchContext)

    useEffect(() => {
      let tempGivenSizeFilter = []
      if (sizesFilter && sizesFilter.length > 0) {
        sizesFilter
          .map((sz) => slugify(sz))
          .forEach((sz) => {
            tempGivenSizeFilter.push(sz)
            if (addSizeFormat(sz)) {
              tempGivenSizeFilter.push(addSizeFormat(sz))
            }
          })
      } else {
        tempGivenSizeFilter = sizesFilter
      }
      setGivenSizeFilter(tempGivenSizeFilter)
    }, [sizesFilter])

    productList.forEach((pr) => {
      if (pr) {
        const showProductDeal =
          showProductDeals &&
          pr.acf &&
          pr.acf.featured_variations &&
          pr.acf.featured_variations.length
        let thisProductSizeFilter = showProductDeal
          ? pr.acf.featured_variations.map((sz) => slugify(sz))
          : givenSizeFilter

        // If this is a variable product (not a bundle)
        if (
          false !== thisProductSizeFilter &&
          pr &&
          pr.product_variations &&
          pr.product_variations.length > 0
        ) {
          pr.product_variations.forEach((vr) => {
            let sizeArr = vr.attributes.filter((att) => att.name == "Size")
            sizeArr = sizeArr.length ? [sizeArr[0].option] : []
            let sizeAttr = []
            sizeArr.forEach((sz) =>
              includes(sz, "/")
                ? split(sz, "/").forEach((nsz) => sizeAttr.push(nsz))
                : sizeAttr.push(sz)
            )
            if (
              thisProductSizeFilter.length === 0 ||
              thisProductSizeFilter.some((sz) =>
                sizeAttr.some((nsz) => slugify(sz) == slugify(nsz))
              )
            ) {
              const stockData = getLocationStockData({
                globalShippingClass: vr.shipping_class || pr.shipping_class,
                globalStockCount: vr.stock_quantity,
                stockByLocation: vr.stock_by_location || pr.stock_by_location,
                selectedBranch,
              })
              let productToPush = {
                name: pr.name,
                slug: pr.slug,
                sku: vr.sku,
                id: vr.id,
                product_list_priority: pr.acf.product_list_priority,
                backorders_allowed: vr.backorders_allowed,
                rating: { average: pr.average_rating, count: pr.rating_count },
                salePrice: vr.price,
                regularPrice: vr.regular_price,
                stockData,
                manageStock: vr.manage_stock,
                image: vr.image,
                selectedSize: sizeArr.length ? sizeArr[0] : undefined,
                parentId: pr.wordpress_id,
                showCompare: showCompareCheck(pr),
              }
              if (checkDiscountLimit(productToPush, discountPercentageLimit)) {
                expandedProductList.push(productToPush)
              }
            }
          })
          // If this is a bundle product, we need to match up the sizes
        } else if (
          pr &&
          pr.bundled_items &&
          Array.isArray(pr.bundled_items) &&
          pr.bundled_items[0] != undefined &&
          pr.bundled_items[1] != undefined &&
          pr.bundled_items[0].product_variations &&
          pr.bundled_items[0].product_variations.length > 0 &&
          pr.bundled_items[1].product_variations &&
          pr.bundled_items[1].product_variations.length > 0
        ) {
          let bundle_matt = pr.bundled_items.filter((bund) =>
            bund.categories.some((cat) => cat.slug == "mattresses")
          )
          bundle_matt = bundle_matt.length ? bundle_matt[0] : {}
          if (
            bundle_matt.product_variations &&
            bundle_matt.product_variations.length > 0
          ) {
            for (const matt of bundle_matt.product_variations) {
              const mattSizeAtt = matt.attributes.find(
                (att) => att.name == "Size"
              )
              const mattSize = mattSizeAtt.option

              if (
                !thisProductSizeFilter ||
                thisProductSizeFilter.includes(slugify(mattSize)) ||
                thisProductSizeFilter.length === 0
              ) {
                let bundle_base =
                  pr.bundled_items.filter(
                    (bund) =>
                      !bund.categories.some((cat) => cat.slug == "mattresses")
                  ) || []
                bundle_base = bundle_base.length ? bundle_base[0] : {}

                // Match up a base with this mattress
                let baseMatchUp = false
                if (
                  bundle_base.product_variations &&
                  bundle_base.product_variations.length > 0
                ) {
                  for (const base of bundle_base.product_variations) {
                    const baseSizeAtt = base.attributes.find(
                      (att) => att.name == "Size"
                    )
                    const baseSize = baseSizeAtt.option

                    if (baseSize == mattSize) {
                      baseMatchUp = base
                      break
                    }
                  }
                }

                // No matching base found, try to match King <-> Single
                if (
                  !baseMatchUp &&
                  (slugify(mattSize) == "king" ||
                    slugify(mattSize) == "king-xl")
                ) {
                  if (
                    bundle_base.product_variations &&
                    bundle_base.product_variations.length > 0
                  ) {
                    for (const base of bundle_base.product_variations) {
                      const baseSizeAtt = base.attributes.find(
                        (att) => att.name == "Size"
                      )
                      const baseSize = baseSizeAtt.option

                      if (
                        (slugify(baseSize) == "single" &&
                          slugify(mattSize) == "king") ||
                        (slugify(baseSize) == "single-xl" &&
                          slugify(mattSize) == "king-xl")
                      ) {
                        baseMatchUp = {
                          ...base,
                          regular_price: parseFloat(base.regular_price) * 2,
                          price: parseFloat(base.price) * 2,
                        }
                        break
                      }
                    }
                  }
                }

                // Still nothing found. Skip this size
                if (!baseMatchUp) {
                  continue
                }

                const combinedSalePrice = matt.price + baseMatchUp.price
                const combinedRegularPrice =
                  matt.regular_price + baseMatchUp.regular_price
                var image = pr.images
                if (
                  pr.acf &&
                  pr.acf.bed_bundle_size_images &&
                  pr.acf.bed_bundle_size_images.filter(
                    (im) =>
                      im.bundle_images.product_image_sku
                        .substr(
                          im.bundle_images.product_image_sku.lastIndexOf("_") +
                            1
                        )
                        .replace(/([A-Z])/g, " $1")
                        .replace(" L", "L")
                        .trim() == mattSize
                  ).length
                ) {
                  image = pr.acf.bed_bundle_size_images.filter(
                    (im) =>
                      im.bundle_images.product_image_sku
                        .substr(
                          im.bundle_images.product_image_sku.lastIndexOf("_") +
                            1
                        )
                        .replace(/([A-Z])/g, " $1")
                        .replace(" L", "L")
                        .trim() == mattSize
                  )[0].bundle_images.image
                }
                const bundleStockByLocation = mergeStockByLocation(
                  matt.stock_by_location,
                  baseMatchUp.stock_by_location
                )
                const stockData = getLocationStockData({
                  globalShippingClass: matt.shipping_class || pr.shipping_class,
                  globalStockCount:
                    matt.stock_quantity <= baseMatchUp.stock_quantity
                      ? matt.stock_quantity
                      : baseMatchUp.stock_quantity,
                  stockByLocation: bundleStockByLocation,
                  selectedBranch,
                })
                let variationToPush = {
                  name: pr.name,
                  slug: pr.slug,
                  sku: pr.sku,
                  id: matt.id,
                  product_list_priority: pr.acf.product_list_priority,
                  backorders_allowed:
                    matt.backorders_allowed && baseMatchUp.backorders_allowed,
                  rating: {
                    average: pr.average_rating,
                    count: pr.rating_count,
                  },
                  salePrice: combinedSalePrice,
                  regularPrice: combinedRegularPrice,
                  stockData,
                  image: image || pr.images,
                  manageStock: matt.manage_stock,
                  selectedSize: mattSize,
                  parentId: pr.wordpress_id,
                  showCompare: showCompareCheck(pr),
                }
                if (false === thisProductSizeFilter) {
                  if (!pr.bundleVariations) {
                    pr["bundleVariations"] = []
                  }
                  pr.bundleVariations.push(variationToPush)
                } else {
                  if (
                    checkDiscountLimit(variationToPush, discountPercentageLimit)
                  ) {
                    expandedProductList.push(variationToPush)
                  }
                }
              }
            }
          }
        } else if (
          false !== thisProductSizeFilter &&
          (thisProductSizeFilter.length
            ? pr.attributes && pr.attributes.length
            : true)
        ) {
          const stockData = getLocationStockData({
            globalShippingClass: pr.shipping_class,
            globalStockCount: pr.stock_quantity,
            stockByLocation: pr.stock_by_location,
            selectedBranch,
          })
          // If the thisProductSizeFilter isn't false, then test thisProductSizeFilter
          // If the length of thisProductSizeFilter is larger than 0, then only show products with sizes
          // If the length of thisProductSizeFilter is 0, then show all products
          let productToPush = {
            name: `${pr.name}`,
            slug: pr.slug,
            sku: pr.sku,
            id: pr.wordpress_id,
            product_list_priority: pr.acf.product_list_priority,
            backorders_allowed: pr.backorders_allowed,
            rating: { average: pr.average_rating, count: pr.rating_count },
            salePrice: pr.price,
            regularPrice: pr.regular_price,
            stockData,
            manageStock: pr.manage_stock,
            image: pr.images,
            showCompare: showCompareCheck(pr),
          }

          if (checkDiscountLimit(productToPush, discountPercentageLimit)) {
            expandedProductList.push(productToPush)
          }
        }
        if (false === thisProductSizeFilter) {
          const stockData = getLocationStockData({
            globalShippingClass: pr.shipping_class,
            globalStockCount: pr.stock_quantity,
            stockByLocation: pr.stock_by_location,
            selectedBranch,
          })
          let productToPush = {
            name: `${pr.name}`,
            slug: pr.slug,
            sku: pr.sku,
            id: pr.wordpress_id,
            product_list_priority: pr.acf.product_list_priority,
            backorders_allowed: pr.backorders_allowed,
            rating: { average: pr.average_rating, count: pr.rating_count },
            salePrice: pr.price ? pr.price : false,
            regularPrice: pr.regular_price ? pr.regular_price : false,
            stockData,
            manageStock: pr.manage_stock,
            image: pr.images,
            showCompare: showCompareCheck(pr),
          }
          if (
            pr &&
            pr.bundleVariations &&
            Array.isArray(pr.bundleVariations) &&
            pr.bundleVariations.length > 0
          ) {
            productToPush.salePrice = false
            productToPush["salePriceRangeMin"] = 0
            productToPush["salePriceRangeMax"] = 0
            for (let prVar of pr.bundleVariations) {
              if (!pr.stock_quantity || pr.stock_quantity < prVar.stockCount) {
                pr.stock_quantity = prVar.stockCount
              }
              if (
                !productToPush.salePriceRangeMin ||
                productToPush.salePriceRangeMin > prVar.salePrice
              ) {
                productToPush.salePriceRangeMin = prVar.salePrice
              }
              if (
                !productToPush.salePriceRangeMax ||
                productToPush.salePriceRangeMax < prVar.salePrice
              ) {
                productToPush.salePriceRangeMax = prVar.salePrice
              }
            }
          } else if (
            pr.product_variations &&
            Array.isArray(pr.product_variations) &&
            pr.product_variations.length > 0
          ) {
            productToPush.salePrice = false
            productToPush["salePriceRangeMin"] = 0
            productToPush["salePriceRangeMax"] = 0
            for (let prVar of pr.product_variations) {
              if (
                !pr.stock_quantity ||
                pr.stock_quantity < prVar.stock_quantity
              ) {
                pr.stock_quantity = prVar.stock_quantity
              }
              if (
                !productToPush.salePriceRangeMin ||
                productToPush.salePriceRangeMin > prVar.price
              ) {
                productToPush.salePriceRangeMin = prVar.price
              }
              if (
                !productToPush.salePriceRangeMax ||
                productToPush.salePriceRangeMax < prVar.price
              ) {
                productToPush.salePriceRangeMax = prVar.price
              }
            }
          }
          if (checkDiscountLimit(productToPush, discountPercentageLimit)) {
            expandedProductList.push(productToPush)
          }
        }
      }
    })

    if (!disableProductOrderSort) {
      // sort products according to menu order
      // this sort needs to run before the other sorts
      // so that the other sorts take precedence
      const productsProductListPriorityMap = {}
      const productsInProductListPriority = []
      const productsWithoutProductListPriority = []
      // split products according to their product_list_priority
      expandedProductList.forEach((prod) => {
        if (
          typeof prod.product_list_priority != "undefined" &&
          prod.product_list_priority
        ) {
          if (
            !Array.isArray(
              productsProductListPriorityMap[prod.product_list_priority]
            )
          ) {
            productsProductListPriorityMap[prod.product_list_priority] = []
          }
          productsProductListPriorityMap[prod.product_list_priority].push(prod)
        } else {
          productsWithoutProductListPriority.push(prod)
        }
      })
      // get a sorted list of all of the product_list_priority values
      const sortedProductListPriorityKeys = Object.keys(
        productsProductListPriorityMap
      ).sort((a, b) => {
        const aInt = parseInt(a)
        const bInt = parseInt(b)
        if (!isNaN(aInt) && !isNaN(bInt)) {
          try {
            const retVal = aInt - bInt
            if (!isNaN(retVal)) {
              return retVal
            }
            // eslint-disable-next-line no-empty
          } catch {}
        }
        return 0
      })
      // add products to productsInProductListPriority according to their product_list_priority
      for (let i = 0; i < sortedProductListPriorityKeys.length; ++i) {
        const productListPriority = sortedProductListPriorityKeys[i]
        for (
          let j = 0;
          j < productsProductListPriorityMap[productListPriority].length;
          ++j
        ) {
          const prod = productsProductListPriorityMap[productListPriority][j]
          productsInProductListPriority.push(prod)
        }
      }
      // add all products without a menu order to the end of productsInProductListPriority
      for (let i = 0; i < productsWithoutProductListPriority.length; ++i) {
        const prod = productsWithoutProductListPriority[i]
        productsInProductListPriority.push(prod)
      }
      // replace expandedProductList with the new productsInProductListPriority list
      expandedProductList = productsInProductListPriority
    }

    // Sorting methods (Price, shipping speed, etc)
    if (sort && Array.isArray(sort) && sort.length > 0) {
      if (sort[0].includes("price")) {
        const sign = sort[0].includes("high-to-low") ? -1 : 1
        expandedProductList.sort((a, b) => {
          let a_price = parseFloat(a.salePrice)
          if (isNaN(a_price)) {
            return sign
          }
          let b_price = parseFloat(b.salePrice)
          if (isNaN(b_price)) {
            return sign
          }
          return (a_price - b_price) * sign
        })
      } else if (sort[0].includes("shipping")) {
        expandedProductList.sort((a, b) => {
          if (a.stockData.shippingClass === b.stockData.shippingClass) {
            return 0
          } else if (
            a.stockData.shippingClass === "delayed-shipping" ||
            b.stockData.shippingClass === "fast-track-shipping"
          ) {
            return 1
          } else if (
            a.stockData.shippingClass === "fast-track-shipping" ||
            b.stockData.shippingClass === "delayed-shipping"
          ) {
            return -1
          }
          return 0
        })
      }
    }

    const renderProducts = (clearFilterIfEmpty, givenSizeFilter) => {
      if (
        clearFilterIfEmpty &&
        givenSizeFilter.length &&
        expandedProductList.length === 0
      ) {
        setGivenSizeFilter([])
      }
      const allShippingValid =
        !shippingFilter.length || shippingFilter.includes("delayed-shipping")
      const noShippingDelay =
        !allShippingValid && shippingFilter.includes("default")
      const fastShippingOnly =
        !noShippingDelay && shippingFilter.includes("fast-track-shipping")
      // only filter expandedProductList if a subset of shipping options is selected
      const productsWithValidShipping = !allShippingValid
        ? []
        : expandedProductList
      if (!allShippingValid) {
        expandedProductList.forEach((prod) => {
          if (
            noShippingDelay &&
            prod.stockData.shippingClass != "delayed-shipping"
          ) {
            productsWithValidShipping.push(prod)
          } else if (
            fastShippingOnly &&
            prod.stockData.shippingClass == "fast-track-shipping"
          ) {
            productsWithValidShipping.push(prod)
          }
        })
      }

      // loop through productsWithValidShipping and create a list of unique parent products
      const parentProducts = {}
      for (let i = 0; i < productsWithValidShipping.length; ++i) {
        parentProducts[productsWithValidShipping[i].sku] = 0
      }
      setParentProductListLength(Object.keys(parentProducts).length)

      // only render the products included in the load-more page
      const productsToRender = productsWithValidShipping.slice(0, currentIndex)

      if (productsToRender.length < 1) {
        return (
          <NoItems>
            <div>There are no products matching your search criteria</div>
          </NoItems>
        )
      }

      return (
        <Grid>
          {productsToRender.map((productToRender, i) => {
            return (
              <LazyLoadWrapper
                key={i}
                placeholderComponent={<ProductCardPlaceholder />}
                lazyComponent={
                  <ProductCard
                    key={i}
                    name={productToRender.name}
                    backorders_allowed={productToRender.backorders_allowed}
                    rating={productToRender.rating}
                    slug={productToRender.slug}
                    sku={productToRender.sku}
                    selectedSize={productToRender.selectedSize}
                    id={productToRender.id}
                    price={productToRender.regularPrice}
                    salePrice={productToRender.salePrice}
                    stockData={productToRender.stockData}
                    // fixed={findFixedImage(productToRender.image)}
                    // fluid={findFluidImage(productToRender.image)}
                    src={findSrcImage(productToRender.image)}
                    parentId={productToRender.parentId}
                    salePriceRangeMin={productToRender.salePriceRangeMin}
                    salePriceRangeMax={productToRender.salePriceRangeMax}
                    showCompare={productToRender.showCompare}
                    searchAnalyticsFunction={searchAnalyticsFunction}
                  />
                }
              />
            )
          })}
        </Grid>
      )
    }

    // Only show the load more button when there are more products to load
    const renderLoadMoreButton = () => {
      if (currentIndex < expandedProductList.length && !homePageBestDeals) {
        return (
          <Button onClick={onLoadMoreProducts}>
            <div>Load More Products</div>
          </Button>
        )
      }
    }

    // When the load more button is clicked, increase currentIndex to display next batch of products
    const onLoadMoreProducts = () => {
      const newCurrentIndex = currentIndex + productsPerLoad
      setCurrentIndex(newCurrentIndex)
    }

    function usePrevious(value) {
      const ref = useRef()
      useEffect(() => {
        ref.current = value
      })
      return ref.current
    }

    useEffect(() => {
      if (
        prevExpandedProductList &&
        expandedProductList &&
        prevExpandedProductList.length !== expandedProductList.length
      ) {
        setCurrentIndex(productsPerLoad)
      }
    }, [expandedProductList])

    return (
      <Inner>
        {renderProducts(clearFilterIfEmpty, givenSizeFilter)}
        {renderLoadMoreButton()}
      </Inner>
    )
  }
)

// These functions allow us to pass any type of image to the struct and
// find whichever one is available, with src as backup
const findFixedImage = (image) => {
  return (
    path([0, "localFile", "childImageSharp", "fixed"], image) ||
    path(["localFile", "childImageSharp", "fixed"], image)
  )
}
const findFluidImage = (image) => {
  return (
    path([0, "localFile", "childImageSharp", "fluid"], image) ||
    path(["localFile", "childImageSharp", "fluid"], image)
  )
}
const findSrcImage = (image) => {
  const retImage =
    path([0, "localFile", "childImageSharp", "fixed", "src"], image) ||
    path(["localFile", "childImageSharp", "fixed", "src"], image) ||
    path([0, "src", "localFile"], image) ||
    path(["src", "localFile"], image) ||
    path([0, "src"], image) ||
    path(["src"], image)
  return retImage
}

export default ProductList

// ======================
// 	🔧🔧 HELPERS 🔧🔧
// ======================
const HI = 9999999

const getLocationStockData = ({
  globalShippingClass,
  globalStockCount,
  stockByLocation,
  selectedBranch,
}) => {
  let shippingClass = globalShippingClass
  let stockCount = globalStockCount

  if (
    selectedBranch &&
    stockByLocation &&
    typeof stockByLocation === "object" &&
    selectedBranch in stockByLocation
  ) {
    const branchStock = stockByLocation[selectedBranch]
    if (
      branchStock &&
      typeof branchStock === "object" &&
      "soh" in branchStock &&
      "effective" in branchStock
    ) {
      shippingClass = lookupShippingClass(
        branchStock.effective,
        branchStock.soh
      )
      stockCount = branchStock.effective
    }
  }

  return {
    stockCount,
    shippingClass,
  }
}

const mergeStockByLocation = (matt, base) => {
  let retStock = {}
  if (matt && base) {
    for (const loc in matt) {
      if (Object.prototype.hasOwnProperty.call(matt, loc) && loc != "sku") {
        if (
          !Object.prototype.hasOwnProperty.call(base, loc) ||
          !matt[loc] ||
          !base[loc] ||
          !("soh" in matt[loc]) ||
          !("soh" in base[loc]) ||
          !("effective" in matt[loc]) ||
          !("effective" in base[loc])
        ) {
          retStock = null
          break
        }
        const soh = Math.min(matt[loc].soh, base[loc].soh)
        const effective = Math.min(matt[loc].effective, base[loc].effective)
        if (isNaN(soh) || isNaN(effective)) {
          retStock = null
          break
        }
        retStock[loc] = {
          soh,
          effective,
        }
      }
    }
  }
  return retStock
}

// Chekcs if the product qualifies for showing the "Compare" icon
const showCompareCheck = (product) => {
  return (
    product &&
    product.categories &&
    (product.categories.some(({ name }) => name == "Mattresses") ||
      product.categories.some(({ name }) => name == "Beds"))
  )
}

const checkDiscountLimit = (product, discountPercentageLimit) => {
  if (discountPercentageLimit > 0) {
    if (
      (product.regularPrice - product.salePrice) / product.regularPrice >
      discountPercentageLimit
    ) {
      return true
    }
    return false
  }
  return true
}

const contains = (xs, val) => xs.some((v) => v === val)

const lowestPriceFromVarients = (variations, filters) => {
  // get all varients where size is present in filters
  const varients = variations.filter(({ attributes }) => {
    return contains(filters, getSize(attributes))
  })

  const low = getLowestPrice(varients)
  return low === HI ? 0 : low
}

const getSize = (attributes) => {
  const { option: size } = attributes.find(({ name }) => name === "Size")
  return size
}

const getLowestPrice = (varients) =>
  varients.reduce((acc, { price }) => {
    return price < acc ? price : acc
  }, HI)

// ======================
// 	💅🏼💅🏼 STYLES 💅🏼💅🏼
// ======================
const ProductCardPlaceholder = () => {
  return (
    <ProductCardPlaceholderStyled>
      <EllipsisLoader height="574px" />
    </ProductCardPlaceholderStyled>
  )
}

const ProductCardPlaceholderStyled = styled.div`
  min-width: 250px;
  height: 574px;
  border-radius: 2px;
  background-color: #ffffff;
  box-shadow: 0 13px 27px 0 rgba(97, 97, 97, 0.25);
  border-radius: 4px;
  overflow: hidden;
  padding: 20px;
  position: relative;
`

const Grid = styled.div`
  display: grid;
  margin-bottom: 50px;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 30px;
  @media (max-width: 1200px) {
    grid-template-columns: repeat(3, 1fr);
  }
  @media (max-width: ${({ theme }) => theme.breakMedium}) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (max-width: 700px) {
    grid-template-columns: repeat(1, 1fr);
  }
`
const Button = styled.div`
  background: #fd3237;
  border-radius: 3px;
  color: white;
  text-transform: uppercase;
  width: fit-content;
  display: flex;
  overflow: hidden;
  align-items: center;
  transition: 0.2s all ease-in-out;
  cursor: pointer;
  margin: 20px auto;
  margin-bottom: 50px;
  &:hover {
    transform: translateX(2px);
  }

  > div {
    padding: 10px 35px;
    font-size: 20px;
  }

  > span {
    padding: 8px;
    background: #da2e32;
  }
`

const NoItems = styled.div`
  padding: 30px;
  margin: 30px;
  border-radius: 10px;
  border: 2px solid #1a293c;
  background: #f9f9f9;
  text-align: center;
`

const Loading = styled.div`
  height: 250px;
`
